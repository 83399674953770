

.clInput {
    border: 2px solid lightgray;
    background-color: lightgray;
    border-radius: 4px;
    /* margin: 10px; */
    width: 100%;
    font-size: 20px;
    /* background-image: url('searchicon.png'); */
}

.clSecurityCode {
    width: 100px;
}

.clMsg {
    color: red;
    text-align: left;
}

.clInfoMsg {
    color: green;
    text-align: left;
}

.clButton {
    background-color: #325E57;
    /* Green */
    border: 5px;
    margin: 10px;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
}

.clButton:hover {
    transition: all .2s ease-in-out;
    transform: scale(.95);
}

.clLink {
    cursor: pointer;
    color: blue;
    text-decoration: none;
}

h3 {
    text-align: center;
}

.clContainer {
    /* border: solid; */
    text-align: center;
    /* position: absolute; */
    /* top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    background-image: 'https://highwoodemissions.com/wp-content/uploads/2021/05/DSC03539-1-1.jpg';
}

.clNiceBox {
    position: relative;
    float: right;
    border: solid;
    width: 500px;
    padding: 50px;
    border-radius: 25px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    padding: 20px 15px 20px 15px;
    text-align: center;
    /* transition: all .2s ease-in-out; */
}

.clRightSide {
    padding: 10px;
    position: relative;
    float: right;
}