.App {
    height: 98vh;
    padding-top: 2vh;
}

.login-required-msg {
    background-color: #fff;
    display: flex;
    justify-content: center;
    font-size: 30px;
    padding: 10px 0px 10px 0px;
}

.loss-control-header {
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70vw;
    margin: auto;
    padding: 0px 10px;
}

.newman-hand-logo {
    height: 60px;
}

.app-name {
    color: #325E57;
}

.tabs {
    overflow: hidden;
    border: 1px solid #ccc;
    border-bottom: none;
    /* border-left: none; */
    background-color: rgb(221, 221, 221);
}

.tab-btn {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    color: #325E57;
}

.tab-btn:hover {
    background-color: rgb(241, 241, 241);
}

.active-tab {
    background-color: #FFFFFF;
}

.pages {
    display: flex;
    flex-direction: column;
    width: 75vw;
    margin: auto;
}

.pages-loading-spinner {
    color: #325E57;
    font-weight: bolder;
    background-color: white;
}

.login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #325E57;
    background-color: #FFFFFF;
    width: 75vw;
    margin: auto;
    padding-bottom: 20px;
}

.lcm-page {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
}

.btns-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 307px;
}

.loss-control-btns-container {
    display: flex;
    justify-content: flex-end;  
    /* margin-top: 5vh; */
}

.lcm-header {
    display: flex;
    justify-content: space-between;
}

.lcm-header-left {
    display: flex;
    color: #325E57;
    margin-left: 10px;
}

.lcm-header-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.lcm-btns-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;
}

.lcm-admin-email {
    color: #325E57;
    margin: 0px 10px;
}

.date-input-container {
    display: flex;
    justify-content: center;  
    margin-top: 5px;
    margin-right: 10px;
    color: #325E57;
}

.date-input {
    margin-left: 2px;
    padding-top: 2px;
}

.loss-control-msg-container {
    display: flex;
    justify-content: center;
    background-color: #fff;
    width: 70vw;
    margin: auto;
    font-size: 20px;
    margin-top: 2vh;
    margin-bottom: 2vh;
    padding: 0px 10px;
}

.message {
    color: green;
}

.error {
    color: red;
}

.loss-control-btn {
    /* font-size: 30px; */
    color: #325E57;
    margin: 0px 10px;
    /* margin-left: 5px;
    margin-right: 5px; */
}

.loss-control-btn:hover {
    color: #0ebfe9;
    cursor: pointer;
}