.client-table {
    display: flex;
    justify-content: center;
}

.client-table-card {
    width: 75vw;
}

.green-cell {
    color: rgb(9, 167, 9);
    font-weight: bold;
    /* background-color: #1db52565; */
}

.red-cell {
    color: rgba(252, 5, 5, 0.878);
    font-weight: bold;
    /* background-color: rgba(252, 5, 5, 0.494); */
}